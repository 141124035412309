<template>

	<div class="cont" id="p08">
		<div class="page-title">{{ program.name }}</div>

		<div class="c01">
			<div class="s01">
				<div class="s-item">
					<div class="s-header">
						<div class="s-title">결제정보</div>
						
					</div>
					<div class="s-body">
						<div class="s-content">
							<div class="si-title">상품명</div>
							<div class="si-desc text-right">{{ item.paymentProductName }}</div>
						</div>
						<div class="s-content">
							<div class="si-title">판매금액</div>
							<div class="si-desc text-right">{{ item.totalPaymentAmount | makeComma }}원</div>
						</div>
						<div class="s-content">
							<div class="si-title">정산수수료</div>
							<div class="si-desc text-right">{{ item.settlementFee | makeComma  }}원</div>
						</div>
						<div class="s-content">
							<div class="si-title">정산금액</div>
							<div class="si-desc text-right">{{ item.settlementAmount | makeComma  }}원</div>
						</div>
						<div class="s-content">
							<div class="si-title">연락처</div>
							<div class="si-desc text-right">{{ item.buyerContact | makeTell }}</div>
						</div>
						<div class="s-content">
							<div class="si-title">부가세</div>
							<div class="si-desc text-right">{{ item.taxInformation }}</div>
						</div>
					</div>
				</div>
				<div class="s-item" style="padding-bottom: 0;">
					<div class="s-header">
						<div class="s-title">결제내역</div>
					</div>
					<div class="s-body">
						<div class="s-content">
							<div class="si-title">승인상태</div>
							<div 
								class="si-desc text-right"
								:class="{ 'txt-error': item.tranStatus != '00' }"
							>{{ statusName }}</div>
						</div>
						<div class="s-content">
							<div class="si-title">승인번호</div>
							<div class="si-desc text-right">{{ item.approvalNo }}</div>
						</div>
						<div class="s-content">
							<div class="si-title">카드번호</div>
							<div class="si-desc">{{ item.cardNumber | makeCardNumber }}</div>
						</div>
						<div class="s-content">
							<div class="si-title">카드종류</div>
							<div class="si-desc">{{ item.cardType}}</div>
						</div>
						<div class="s-content">
							<div class="si-title">할부</div>
							<div class="si-desc">{{ item.installment }}</div>
						</div>
						<div class="s-content">
							<div class="si-title">거래일시</div>
							<div class="si-desc">{{ item.approvalDate | transDateTime }}</div>
						</div>
						<div class="s-content">
							<div class="si-title">공급가</div>
							<div class="si-desc">{{ item.supplyAmount | makeComma }}원</div>
						</div>
						<div class="s-content">
							<div class="si-title">부가세</div>
							<div class="si-desc">{{ item.vatAmount | makeComma }}원</div>
						</div>
						<div class="s-content">
							<div class="si-title">결제금액</div>
							<div class="si-desc">{{ item.totalPaymentAmount | makeComma }}원</div>
						</div>
					</div>
				</div>
				<div class="s-item" style="padding-bottom: 0;">
					<div class="s-header">
						<div class="s-title">영업점</div>
					</div>
					<div class="s-body">
						<div class="s-content">
							<div class="si-title">영업담당자</div>
							<div class="si-desc">{{ item.salesName }}</div>
						</div>
						<div class="s-content">
							<div class="si-title">연락처</div>
							<div class="si-desc">{{ item.salesContact | makeTell }}</div>
						</div>
					</div>
				</div>
			</div>

			<div class="s03">
				<a 
					v-if="viewCancel"
					@click="cancel"><div class="btn-cancel mr-10">결제 취소</div></a>
				<a 
					@click="viewBill"><div class="btn-save mr-10">영수증</div></a>
				<a 
					@click="$router.go(-1)"><div class="btn-back">목록</div></a>
			</div>
		</div>
		
		<PaymentsPopupBill 
			v-if="view.bill"
			:item="item"
			
			@setOverlay="setOverlay"
			@setNotify="setNotify"
		/>
		
		</div>
</template>

<script>

	import { Axios } from '@/resources/axios/axios'
	import { rules } from '@/resources/rules/rules'
	import { filter } from '@/resources/filter/filter'
	import { date } from '@/resources/date/date'
	
	import PaymentsPopupBill from '@/view/Payments/PaymentsPopupBill'
	
	export default{
		name: "PaymentsHistoryDetail"
		,props: ['user', 'codes']
		,components: { PaymentsPopupBill }
		,data: function(){
			return {
				program: {
					name: this.$language.menu[0].list[1].name
				}
				,index: this.$route.params.index
				,item: {
					basic: {}
					,manger: {}
					,company: {}
					,settlement: {}
					,paymentItems: {}
				}
				,view: {
					bill: false
				}
				,rules:rules
			}
		}
		,filter: filter
		,computed: {
			viewCancel: function(){
				
				const now = date.getToday()

				if(this.item.tranStatus == '00' && this.item.tranDt == now){
					return true
				}else{
					return false
				}
			}
			,statusName: function(){
			
				if(this.item.tranStatus){
					let index = this.codes.transStats.map(function(o){ return o.code; }).indexOf(this.item.tranStatus)
					return this.codes.transStats[index].codeName
				}else{
					return ''
				}
				
			}
		}
		,methods: {
			getData: async function(){
				try{
					const result = await Axios({
						method: 'get'
						,url: '/payments/' + this.index + '/detail'
						,authorize: true
					})
					
					if(result.success){
						this.item = result.data.content
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message})
					}
				}catch(E){
					console.log(E)
					this.$emit('setNotify', { type: 'error', message: E.status})
				}
			}
			,cancel: async function(){
				if(confirm(this.$language.common.askCancel)){
					try{
						const result = await Axios({
							method: 'post'
							,url: '/pay/cancel'
							,data: {
								tranSeq: this.index
							}
							,authorize: true
							,paylink: true
						})
						
						if(result.success){
							window.location.reload()
						}else{
							this.$emit('setNotify', { type: 'error', message: result.message })
						}
					}catch(E){
						console.log(E)
						this.$emit('setNotify', { type: 'error', message: E.status})
					}
				}
			}
			,viewBill: function(){
				this.view.bill = true
				this.$emit('setOverlay', true)
			}
			,setOverlay: function(type){
				this.$emit('setOverlay', type)
				if(!type){
					this.view.bill = false
				}
			}
			,setNotify: function(option){
				this.$emit('setNotify', option)
			}
		}
		,created: function(){
			this.getData()
		}
		,watch: {
			managerMobilPhoneNumber: {
				deep: true
				,handler: function(call){
					this.item.manger.managerMobilPhoneNumber = call.fir + call.sec + call.thi
				}
			}
		}
	}
</script>

<style>
	#p08 .si-desc { text-align: right !important;}
</style>





















